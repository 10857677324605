@import '~theme/variables';

.container {
  position: relative;
  padding-bottom: 7.2rem;
  margin-left: -#{map_get($layout-grid-offset, xs)};

  @include breakpoint(sm) {
    margin-left: 0;
  }

  @each $size, $value in $layout-grid-offset {
    @include breakpoint($size) {
      margin-right: -#{$value};
    }
  }
}

.container__alternative {
  right: 0;
  margin: 0;
  padding-bottom: 0;

  .item {
    &:hover img {
      transform: none;
    }
  }
}

.containerButtonsInside {
  margin-bottom: 2.4rem;
  margin-right: 0;
}

.item {
  position: relative;
  border-radius: 1.6rem;
  overflow: hidden;
  cursor: grab;

  @include breakpoint(md) {
    cursor: pointer;
  }

  @each $size, $value in $layout-grid-col-gap {
    @include breakpoint($size) {
      margin-right: $value;
    }
  }
}

.item__fullscreen {
  margin: 10%;
}

.image {
  position: relative;
  width: 100%;
  border-radius: $border-radius-03;
  box-shadow: $box-shadow-01;
  transition: transform 0.2s ease-in-out;
  aspect-ratio: 3/2;

  img {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.caption {
  text-align: left;
  padding-bottom: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: 0px 1px 4px $color-neutral-01, 0px 1px 0px $color-neutral-01;

  background: linear-gradient(
    360deg,
    rgba(16, 15, 13, 0.7) 0%,
    rgba(217, 217, 217, 0) 54.17%
  );
}

.caption h3,
.caption p {
  margin-left: 2rem;
}

.caption__alternative {
  background: $color-primary;
  color: $color-neutral-06;
  border-radius: $border-radius-01;
}

.subtitle {
  background: none;
  text-align: center;
}

.fullscreen {
  position: relative;
  display: flex !important; /* replace react-slick style */
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0;
  @include breakpoint(md) {
    padding: 10rem;
  }
}

.link {
  flex: 1;
  background: $color-neutral-05;
  border-radius: $border-radius-01;
  padding: 2.4rem 1.6rem 1.6rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.link__fullscreen {
  top: -5.2rem;
  width: 50vw;
  max-height: 30vh;
  background: none;
  justify-content: center;
}
