@import '~theme/variables';

.container {
  width: 100%;
}

.slide {
  padding-bottom: 100%;
}

.card {
  width: 100%;
  overflow: visible;
}
