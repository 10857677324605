@import '~theme/variables';

.card {
  aspect-ratio: 1/1;
  border-radius: $border-radius-04;
  background: #eee;
  position: absolute;
  overflow: hidden;
}

.media {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.iconWrapper {
  position: absolute;
  z-index: 1;
  left: $space-s;
  bottom: $space-s;

  @include breakpoint('md') {
    left: $space-m;
    bottom: $space-m;
  }
}

.icon {
  fill: $color-neutral-06;
  width: 2.4rem;
  height: 2.4rem;
}

.link {
  &::after {
    position: absolute;
    inset: 0;
    content: '';
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
}
